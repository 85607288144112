.home-page {
  .banner {
    text-align: center;
    .list {
      display: flex;
      justify-content: center;
    }
  }
}
.section-about {
  padding: 180px 10px 0;
  @media (max-width: 991px) {
    padding: 80px 10px 0;
  }
  .container {
    padding-top: 25px;
    padding-bottom: 25px;
    border: 0.5px solid #69bd4d1a;
    border-radius: 24px;
    .content {
      padding: 25px;
      border-radius: 24px;
      border: 0.75px solid #69bd4d1a;
      @media (max-width: 991px) {
        padding: 0px;
        border: none;
      }
      .icon-left {
        position: absolute;
        top: -20px;
        left: 20px;
      }
      .img,
      .img-inner {
        position: relative;
        text-align: center;
        padding: 25px;
        border-radius: 24px;
        border: 0.75px solid #69bd4d1a;
        @media (max-width: 991px) {
          padding: 10px;
          border: none;
        }
        img {
          position: relative;
          text-align: center;
          padding: 25px;
          border-radius: 24px;
          border: 0.75px solid #69bd4d1a;
        }
      }
      .inner {
        padding-left: 25px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .des {
          max-width: 800px;
        }
        .icon-right {
          text-align: right;
          img {
            max-width: 105px;
          }
        }
      }
      .action {
        .btn {
          cursor: pointer;
          color: #fff;
          background: #3a8ef6;
          box-shadow: 0 2px 0 rgba(5, 155, 255, 0.06);
          font-size: 16px;
          line-height: 1.5;
          height: 40px;
          padding: 7px 15px;
          border-radius: 8px;
          transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          user-select: none;
          touch-action: manipulation;
          &:hover {
            background: #66b0ff;
          }
        }
      }
    }
  }
}
.section-product {
  padding: 180px 0 0;
  background-color: #fafafa;
  background: url(../../../assets/img/product-bg.png) no-repeat bottom left;
  @media (max-width: 991px) {
    padding: 80px 10px 0;
  }
  .head {
    text-align: center;
    .des {
      margin: 0 auto;
      max-width: 670px;
    }
  }
  .content {
    margin: 45px 0 0;
    padding: 0 150px 800px 150px;
    border-radius: 0 100px 0 0;
    @media (max-width: 991px) {
      padding: 0 10px;
      background: none;
    }
    > .row {
      padding: 100px 0 60px;
      @media (max-width: 991px) {
        padding: 50px 0;
      }
      .col-product {
        @media (max-width: 991px) {
          margin-bottom: 50px;
        }
      }
    }
    .title-top {
      font-family: "Roboto", sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 28.13px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #3a8ef6;
      text-transform: uppercase;
      margin: 0 0 20px;
    }
    .title {
      font-size: 40px;
      line-height: 46.88px;
    }
    .des {
      font-size: 18px;
      line-height: 31.13px;
    }
    .img {
      .img-inner {
        text-align: center;
      }
    }
    .icon-bottom {
      position: absolute;
      bottom: -60px;
      left: 20%;
      img {
        max-width: 125px;
      }
    }
    .icon-top {
      position: absolute;
      top: 0px;
      left: 50%;
      img {
        max-width: 37px;
      }
    }
    .icon-right {
      position: absolute;
      top: 50%;
      right: 10%;
      img {
        max-width: 69px;
      }
    }
    .icon-left {
      position: absolute;
      left: 8%;
      top: 20%;
      img {
        max-width: 50px;
      }
    }
  }
}
.section-partner {
  margin: -250px 0 0;
  @media (max-width: 991px) {
    margin: 0;
  }
  .head {
    .des {
      max-width: 630px;
      margin: 0 auto;
    }
  }
  .partner-list {
    margin: 70px 0 0;
    display: flex;
    flex-wrap: wrap;
    .partner-item {
      flex: 16.66%;
      text-align: center;
      margin: 0 0 75px;
      @media (max-width: 991px) {
        flex: 33.33%;
      }
      @media (max-width: 639px) {
        flex: 50%;
      }
    }
  }
}
.section-contact {
  margin: 100px 0 0;
  padding: 120px 125px 350px;
  @media (max-width: 991px) {
    padding: 50px 25px 50px;
  }
  background: url(../../../assets/img/contact-bg.png) no-repeat bottom center;
  .name {
    font-family: "Roboto", sans-serif;
    font-size: 64px;
    font-weight: 700;
    line-height: 89.6px;
    text-align: left;
    color: #1d2130;
    @media (max-width: 991px) {
      font-size: 40px;
      line-height: 50px;
    }
  }
  .sub {
    margin: 10px 0 30px;
    font-family: "Roboto", sans-serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 62.88px;
    text-align: left;
    color: #1d2130;
    @media (max-width: 991px) {
      font-size: 30px;
      line-height: 35px;
    }
  }
  .btn {
    background: #ea6a00;
    font-size: 48px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #ffffff;
    border-radius: 41px;
    padding: 32px 50px;
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    @media (max-width: 991px) {
      font-size: 28px;
      line-height: 35px;
      padding: 15px 25px;
    }
    &:hover {
      opacity: 0.8;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
  }
}
