@import "variable";
.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  @media (min-width: 1200px) {
    max-width: 1920px;
  }
}
img {
  max-width: 100%;
}
.pointer {
  cursor: pointer;
}
iframe#webpack-dev-server-client-overlay {
  display: none;
}
.truncate-line {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.truncate-line-2 {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.title {
  font-family: "Roboto", sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 56.25px;
  color: #000000;
  margin: 0 0 32px;
  @media (max-width: 991px) {
    font-size: 40px;
    line-height: 50px;
  }
}
.des {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 31.13px;
  color: #031432cc;
  margin: 0 0 16px;
}
.header {
  position: fixed;
  z-index: 2;
  top: 0;
  background: #fff;
  width: 100%;
  padding: 0 185px 0 115px;
  box-shadow: 0px 0px 7px #ada2a2;
  @media (max-width: 991px) {
    padding: 0 0px;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      img {
        max-width: 150px;
        @media (max-width: 991px) {
          max-width: 100px;
        }
      }
    }
    .menu-list {
      display: flex;
      .menu-item {
        text-decoration: none;
        cursor: pointer;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 21.09px;
        text-align: left;
        color: #000000;
        margin: 0 28px;
        @media (max-width: 639px) {
          margin: 0 5px;
          font-size: 12px;
        }

        &.active,
        &:hover {
          color: #0e76bc;
        }
      }
    }
  }
}
.page-main {
  margin: 78px 0 0;
}
.footer {
  padding: 0 155px;
  font-family: "Roboto", sans-serif;
  @media (max-width: 1750px) {
    padding: 0 55px;
  }
  @media (max-width: 1335px) {
    padding: 0 15px;
  }
  @media (max-width: 991px) {
    padding: 0 15px;
  }
  .footer-top {
    padding: 69px 0 30px;
    border-bottom: 1px solid #1211271f;
    .logo-wrap {
      @media (max-width: 1750px) {
        display: flex;
        align-items: center;
      }
      img {
        flex: 50%;
        @media (max-width: 1750px) {
          max-width: 120px;
        }
        &:first-child {
          margin: 0 30px 0 0;
          @media (max-width: 1750px) {
            margin: 0;
          }
        }
      }
    }
    .sub {
      margin: 50px 0 0;
      font-size: 15.34px;
      font-weight: 400;
      line-height: 17.98px;
      text-align: left;
      color: #313131;
      @media (max-width: 1750px) {
        font-size: 13px;
      }
      @media (max-width: 1280px) {
        font-size: 12px;
      }
      @media (max-width: 991px) {
        margin: 25px 0 50px;
      }
    }
    .name {
      font-size: 15.34px;
      font-weight: 600;
      line-height: 26.08px;
      letter-spacing: 0.8767123222351074px;
      text-align: left;
      color: #31313166;
      margin: 0 0 20px;
      text-transform: uppercase;
      @media (max-width: 1750px) {
        font-size: 14px;
      }
    }
    .list {
      @media (max-width: 991px) {
        margin: 0 0 50px;
      }
      .item,
      a {
        font-family: "Roboto", sans-serif;
        font-size: 17.53px;
        font-weight: 400;
        line-height: 31.56px;
        text-align: left;
        color: #313131;
        margin: 0 0 8px;
        @media (max-width: 1750px) {
          font-size: 14px;
        }
        @media (max-width: 1280px) {
          font-size: 13px;
        }
      }
      a {
        &:hover {
          color: blue;
        }
      }
    }
  }
  .footer-bottom {
    padding: 40px 0 43px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 991px) {
      flex-direction: column;
    }
    .left {
      font-size: 17.53px;
      font-weight: 400;
      line-height: 31.56px;
      text-align: left;
      color: #3131318f;
      @media (max-width: 1750px) {
        font-size: 14px;
      }
      @media (max-width: 1280px) {
        font-size: 13px;
      }
      @media (max-width: 991px) {
        margin: 0 0 15px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .language {
        font-size: 15.34px;
        font-weight: 400;
        line-height: 26.3px;
        letter-spacing: 0.5479452013969421px;
        text-align: left;
        color: #12112766;
        margin: 0 25px 0 0;
        @media (max-width: 1750px) {
          font-size: 14px;
        }
        @media (max-width: 1280px) {
          font-size: 13px;
        }
        @media (max-width: 991px) {
          margin: 0 15px 0 0;
          font-size: 14px;
          line-height: 20x;
        }
      }
      .list {
        display: flex;
        .item {
          margin: 0 26px;
          @media (max-width: 991px) {
            margin: 0 12px;
          }
        }
      }
    }
  }
}
