$error: rgb(244, 67, 54);
$colorPrimary: #3A8EF6;
$colorGrey: #78909C;
$colorBlur: #5B93FF;

:export {
  error: $error;
  colorPrimary: $colorPrimary;
  colorGrey: $colorGrey;
  colorBlur: $colorBlur;
}
