.table-logs {
  color: #808994;
}

.paging {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  font-size: 14px;
  color: #808994;
  margin: 30px auto 0;
  flex: 1;

  &__page {
    position: relative;

    input {
      background: transparent;
      outline: none;
      border: 1px solid #3c3f47;
      text-align: center;
      color: #fff;
      margin: 0 8px;
      width: 70px;
      padding: 5px 0;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &__action {
    background-color: #3c3f47;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    margin-left: 15px;

    button {
      background: transparent;
      border: none;
      outline: none;
      padding: 5px 10px;

      &:disabled {
        cursor: not-allowed;
      }
    }

    i {
      color: #fff;
    }
  }
}
